.nimber-talent-block {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.nimber-talent-left {
    width: 50%;
}



@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nimber-talent-block {
        flex-direction: column;
    }

    .nimber-talent-left {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .nimber-talent-block {
        flex-direction: column;
    }

    .nimber-talent-img {
        display: none;
    }

    .nimber-talent-left {
        width: 100%;
    }

}