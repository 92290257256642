.sent-wrapper {
    padding: 54px 62px 54px 46px;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 32px;
}

.sent-top-text {
    font-size: 30px;
    font-weight: 900;
    color: #171717;
    margin: 0;
}

.sent-middle-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    color: #171717;
    margin: 0;
}

.sent-bottom-text {
    font-size: 16px;
    line-height: 24px;
    color: #171717;
    margin: 0;
}