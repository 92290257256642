.cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.cookie-centering {
    width: 80%;
    padding: 25px;
    background-color: #FFF;
    align-items: center;
    display: flex;
    flex-direction: row;
    border: 1px solid #D9DBE9;
    border-radius: 8px;
}

.cookie-main-text-color {
    color: #000144;
    margin: 0;
    padding-left: 24px;
}

.cookie-terms-color {
    color: #0227B0;
}

.cookie-sub-terms {
    font-size: 16px;
    line-height: 24px;
}

.cookie-terms-header {
    font-size: 20px;
}

.nimber-full-button {
    padding: 20px;
    border: none;
    background: #3552C0;
    border-radius: 6px;
    color: #FAFAFA;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    margin-right: 20px;
}

.nimber-full-button:hover {
    background: #0227B0;
}

.nimber-full-button:focus {
    border: 3px solid #011561;
}

.nimber-semi-button {
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #3552C0;
    background: #FAFAFA;
    color: #3552C0;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    margin-right: 60px;
}

.nimber-semi-button:hover {
    background: #E7E7E7;
}

.nimber-semi-button:focus {
    border: 3px solid #3552C0;
}

.button-wrapper {
    display: flex;
    align-items: flex-end;
}

.center-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.desktop-close {
    height: 100%;
}

.mobile-close {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .cookie-centering {
        flex-direction: column;
        justify-content: flex-start;
        gap: 24px;
    }

    .center-wrapper{
        flex-direction: column;
        gap: 24px;
    }

    .cookie-img-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .cookie-main-text-color {
        padding: 0;
    }

    .button-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .nimber-semi-button{
        margin: 0;
        width: 100%;
    }

    .nimber-full-button{
        width: 100%;
        margin-bottom: 20px;
    }

    .desktop-close {
        display: none;
    }

    .mobile-close {
        display: block;
    }
}