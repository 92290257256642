.nimber-growing-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 80px;
    background: #FAFAFA;
}

.nimber-growing-three-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
}

.nimber-growing-single-block {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px;
    min-width: 320px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10);
}



@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nimber-growing-three-block {
        flex-direction: column;
    }

    .nimber-growing-wrapper {
        padding: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .nimber-growing-three-block {
        flex-direction: column;
    }

    .nimber-growing-wrapper {
        padding: 16px;
    }
}