.nimber-top-footer {
    padding: 24px 80px;
    display: flex;
    justify-content: center;
    background: #000260;
}

.nimber-bottom-footer {
    padding: 24px 80px;
}

.nimber-bottom-footer-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.fixed-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fixed-footer .nimber-button {
    margin-right: 20px;
}

.fixed-footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    box-shadow: 0 -5px 50px 0 rgba(0, 0, 0, 0.10);
}

@media only screen and (max-width: 767px) {
    .fixed-footer {
        flex-direction: column;
    }

    .fixed-footer-container {
        width: 100vw;
        padding: 0;
        padding-bottom: 10px;
    }

    .fixed-footer .nimber-button {
        margin-right: 0;
    }
}