.left-arrow {
    left: 15px;
    border-radius: 6px;
    background: #D9D9D9;
    width: 46px;
    height: 46px;
    z-index: 1;
}

.left-arrow:hover {
    background: #D9D9D9 !important;
}

.left-arrow img {
    width: 46px;
    height: 46px;
}

.slick-prev::before {
    content: '' !important;
}

.right-arrow {
    right: 5px;
    border-radius: 6px;
    background: #D9D9D9;
    width: 46px;
    height: 46px;
}

.right-arrow:hover {
    background: #D9D9D9 !important;
}

.right-arrow img {
    width: 46px;
    height: 46px;
}

.slick-next::before {
    content: '' !important;
}


@media only screen and (max-width: 767px) {
    .left-arrow {
        display: none;
    }

    .right-arrow {
        display: none;
    }
}