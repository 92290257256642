.form-group {
    margin-bottom: 15px;
}

.form-wrapper {
    padding: 54px 62px 54px 46px;
    background: #FFFFFF;
    border-radius: 8px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.3px;
}

.form-group-input {
    width: 100%;
    padding: 0 0 0 16px;
    height: 48px;
    border: 1px solid #D6D6D6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #646464;
    box-sizing: border-box;
}

.form-head-text {
    color: #171717;
    font-size: 30px;
    font-weight: 900;
}

.form-group-checkbox {
    width: 24px;
    height: 24px;
    margin: 0;
}

.form-checkbox-label {
    margin: 0;
    height: 24px;
}

.checkbox-text {
    color: #181A44;
    font-size: 12px;
    line-height: 16px;
    padding-left: 8px;
}

.terms-link {
    color: #0227B0;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}