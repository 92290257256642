.nimber-head {
    background: #F5F7FF;
    display: flex;
    padding: 80px;
    justify-content: center;
}

.nimber-head-sub {
    display: flex;
    gap: 120px;
}

.nimber-head-left {
    width: 55%;
}


@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nimber-head-sub {
        flex-direction: column;
    }

    .nimber-head-left {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .nimber-head-sub {
        flex-direction: column;
        width: 100vw;
    }

    .nimber-head-left {
        width: 95vw;
        padding: 16px;
        box-sizing: border-box;
    }
}