.nimber-main-title {
    font-size: 66px;
    font-weight: 700;
}

.nimber-secondary-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.nimber-t-copyright {
    font-size: 16px;
    line-height: 22px;
}

.nimber-t-footer {
    font-size: 25px;
    font-weight: 900;
}

.nimber-t-study-info {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
}

.nimber-foundation-neutral-500 {
    color: #171717;
}

.nimber-primary-primary-500 {
    color: #000260;
}

.nimber-neutral-neutral-50 {
    color: #FAFAFA;
}

@media (max-width: 767px) {
    .nimber-main-title {
        font-size: 30px;
        max-width: 100vw;
    }

    .nimber-secondary-text {
        font-size: 16px;
        line-height: 24px;
    }
}