.nimber-button {
    padding: 16px;
    border: none;
    background: #3BE4FF;
    border-radius: 6px;
    color: #000260;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
}

.nimber-button img {
    margin-left: 16px;
}

.nimber-button:hover {
    background: #A5F3FF;
    cursor: pointer;
}

.nimber-button:active {
    background: #36CFE8;
}

.nimber-button:focus {
    border: 3px solid #2AA2B5;
}