.nimber-study-block {
    display: flex;
}

.nimber-study-left {
    width: 50%;
}

.nimber-study-right {
    margin-left: 48px;
}

.nimber-case-techs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 36px;
}

.slick-slider {
    padding: 80px;
}


@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nimber-study-block {
        flex-direction: column;
    }

    .nimber-study-left {
        width: 100%;
    }

    .nimber-study-right {
        margin-left: 0;
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .nimber-study-block {
        flex-direction: column;
        padding: 20px;
    }

    .nimber-study-left {
        width: 100%;
    }

    .nimber-study-right {
        margin-top: 20px;
        margin-left: 0;
        display: none;
    }

    .slick-slider {
        padding: 16px;
    }
}