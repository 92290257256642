.nimber-g-margin-top-32 {
    margin-top: 32px !important;
}

.nimber-g-margin-top-24 {
    margin-top: 24px !important;
}

.nimber-g-margin-top-16 {
    margin-top: 16px !important;
}

.nimber-g-margin-bottom-40 {
    margin-bottom: 40px !important;
}

.nimber-clean {
    margin: 0;
    padding: 0;
}

.nimber-g-width-100 {
    width: 100%;
}

.nimber-max {
    max-width: 1440px;
}

.nimber-flex-center {
    display: flex;
    justify-content: center;
}

.slick-slider {
    overflow: hidden;
    cursor: grab;
}

.slide-content-wrapper {
    width: 100%;
}

img {
    max-width: 100%;
}