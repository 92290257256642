.nimber-tag {
    width: max-content;
    padding: 0 12px;

    border-radius: 4px;
    background: #EBFCFF;

    color: #19606B;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    max-width: 90vw;
}

.nimber-tag-holder {
    display: flex;
    justify-content: center;
}

@media (max-width: 767px) {
    .nimber-tag {
        font-size: 12px;
    }

    .nimber-tag-holder {
        max-width: 100vw;
    }
}