.nimber-client-wrapper {
    background: #FAFAFA;
    display: flex;
    padding: 90px 70px;
    justify-content: center;
}

.nimber-client-sub {
    display: flex;
    align-items: center;
    gap: 60px;
}